import Vue from 'vue'

// 注册自定义拖拽指令，弥补 modal 组件不能拖动的缺陷
Vue.directive('drag-modal', (el, bindings, vnode) => {
  Vue.nextTick(() => {
    const { visible, destroyOnClose } = vnode.componentInstance
    // 防止未定义 destroyOnClose 关闭弹窗时dom未被销毁，指令被重复调用
    if (!visible) return
    const modal = el.getElementsByClassName('ant-modal')[0]
    const header = el.getElementsByClassName('ant-modal-header')[0]
    let left = 0
    let top = 0

    // 设置遮罩层可滚动
    setTimeout(() => {
      document.body.style.width = '100%'
      document.body.style.overflowY = 'inherit'
    }, 0)

    // 鼠标变成可移动的指示
    header.style.cursor = 'move'

    // 未定义 destroyOnClose 时，dom未被销毁，关闭弹窗再次打开，弹窗会停留在上一次拖动的位置
    if (!destroyOnClose) {
      left = modal.left || 0
      top = modal.top || 0
    }
    // top 初始值为 offsetTop
    top = top || modal.offsetTop
    header.onmousedown = e => {
      const startX = e.clientX
      const startY = e.clientY
      header.left = header.offsetLeft
      header.top = header.offsetTop
      el.onmousemove = event => {
        const endX = event.clientX
        const endY = event.clientY
        modal.left = header.left + (endX - startX) + left
        modal.top = header.top + (endY - startY) + top
        modal.style.left = modal.left + 'px'
        modal.style.top = modal.top + 'px'
      }
      el.onmouseup = event => {
        left = modal.left
        top = modal.top
        el.onmousemove = null
        el.onmouseup = null
        header.releaseCapture && header.releaseCapture()
      }
      header.setCapture && header.setCapture()
    }
  })
})
 
// v-dialogDrag: 弹窗拖拽
Vue.directive('dragModal', {
  bind(el, binding, vnode, oldVnode) {
    // 弹框可拉伸最小宽高
    const minWidth = 400;
    const minHeight = 300;
    // 获取弹框头部（这部分可双击全屏）
    const dialogHeaderEl = el.querySelector('.ant-modal')
    // 弹窗
    const dragDom = el.querySelector('.ant-modal-wrap')
    // 头部加上可拖动cursor
    dialogHeaderEl.style.cursor = 'move';
    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);
    dialogHeaderEl.onmousedown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - dialogHeaderEl.offsetLeft;
      const disY = e.clientY - dialogHeaderEl.offsetTop;
      // 获取到的值带px 正则匹配替换
      let styL, styT;
      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (sty.left.includes('%')) {
        // eslint-disable-next-line no-useless-escape
        styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100);
        // eslint-disable-next-line no-useless-escape
        styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100);
      } else {
        styL = +sty.left.replace(/\px/g, '');
        styT = +sty.top.replace(/\px/g, '');
      }
 
      document.onmousemove = function(e) {
        // 通过事件委托，计算移动的距离
        const l = e.clientX - disX;
        const t = e.clientY - disY;
        // 移动当前元素
        dragDom.style.left = `${l + styL}px`;
        dragDom.style.top = `${t + styT}px`;
 
        // 将此时的位置传出去
 
        // binding.value({x:e.pageX,y:e.pageY})
      };
 
      document.onmouseup = function(e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    }
 
    dragDom.onmousemove = function(e) {
      // console.log(dialogHeaderEl,dragDom.querySelector('.el-dialog__body'), 123);
      const moveE = e;
 
      if (e.clientX > dragDom.offsetLeft + dragDom.clientWidth - 10 || dragDom.offsetLeft + 10 > e.clientX) {
        dragDom.style.cursor = 'se-resize';
      } else if (el.scrollTop + e.clientY > dragDom.offsetTop + dragDom.clientHeight - 10) {
        dragDom.style.cursor = 's-resize';
      } else {
        dragDom.style.cursor = 'default';
        dragDom.onmousedown = null;
      }
 
      dragDom.onmousedown = (e) => {
        const clientX = e.clientX;
        const clientY = e.clientY;
        const elW = dragDom.clientWidth;
        const elH = dragDom.clientHeight;
        const EloffsetLeft = dragDom.offsetLeft;
        const EloffsetTop = dragDom.offsetTop;
        dragDom.style.userSelect = 'none';
        const ELscrollTop = el.scrollTop;
        // 判断点击的位置是不是为头部
        if (clientX > EloffsetLeft && clientX < EloffsetLeft + elW && clientY > EloffsetTop && clientY < EloffsetTop + 100) {
          // 如果是头部在此就不做任何动作，以上有绑定dialogHeaderEl.onmousedown = moveDown;
        } else {
          document.onmousemove = function(e) {
            e.stopPropagation(); // 移动时禁用默认事件
            // 左侧鼠标拖拽位置
            if (clientX > EloffsetLeft && clientX < EloffsetLeft + 10) {
              // 往左拖拽
              if (clientX > e.clientX) {
                dragDom.style.width = elW + (clientX - e.clientX) * 2 + 'px';
              }
              // 往右拖拽
              if (clientX < e.clientX) {
                // eslint-disable-next-line no-empty
                if (dragDom.clientWidth < minWidth) {
                } else {
                  dragDom.style.width = elW - (e.clientX - clientX) * 2 + 'px';
                }
              }
            }
            // 右侧鼠标拖拽位置
            if (clientX > EloffsetLeft + elW - 10 && clientX < EloffsetLeft + elW) {
              // 往左拖拽
              if (clientX > e.clientX) {
                // eslint-disable-next-line no-empty
                if (dragDom.clientWidth < minWidth) {
                } else {
                  dragDom.style.width = elW - (clientX - e.clientX) * 2 + 'px';
                }
              }
              // 往右拖拽
              if (clientX < e.clientX) {
                dragDom.style.width = elW + (e.clientX - clientX) * 2 + 'px';
              }
            }
            // 底部鼠标拖拽位置
            if (ELscrollTop + clientY > EloffsetTop + elH - 20 && ELscrollTop + clientY < EloffsetTop + elH) {
              // 往上拖拽
              if (clientY > e.clientY) {
                // eslint-disable-next-line no-empty
                if (dragDom.clientHeight < minHeight) {
                } else {
                  dragDom.style.height = elH - (clientY - e.clientY) * 2 + 'px';
                }
              }
              // 往下拖拽
              if (clientY < e.clientY) {
                dragDom.style.height = elH + (e.clientY - clientY) * 2 + 'px';
              }
            }
          };
 
          // 拉伸结束
          document.onmouseup = function(e) {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        }
      }
    }
  }
})
