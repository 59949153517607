<template>
  <div class="hello">
    <div class="toolbar">
      <div class="h1 c1 bor1">常用功能</div>
      <div class="list">
        <div class="item" v-for="(l, i) in list" :key="i" @click="goPage(l)">
          <div class="iconImg">
            <img v-if="iconList.indexOf(l.function_icon) != -1" :src="require(`../assets/img/${l.function_icon}.png`)" alt="" />
            <img v-else src="../assets/img/工作计划.png" alt="" />
          </div>
          <div class="name">{{ l.menu_title }}</div>
        </div>
        <div class="item add">
          <a-icon
            type="plus-circle"
            class="add-icon"
            @click="add_toolbar"
            :style="{ fontSize: '40px', color: iconColor }"
          />
          <div class="name">添加</div>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <a-modal
        wrapClassName="popup-up"
        v-model="visible"
        title="常用功能设置"
        @ok="handleOk"
        okText="保存"
        cancelText="取消"
        width="700px"
        destroyOnClose
        v-drag-modal
      >

        <div class="popup-content">
          <div class="left">
            <div class="title">所有系统功能</div>
            <div class="tree">
              <a-tree
                v-model="checkedKeys"
                checkable
                :auto-expand-parent="autoExpandParent"
                :tree-data="treeData"
                @check="selectBtn"
                :replaceFields='modalText'
                :disabled="ifDisabled"
              />
            </div>
          </div>
          <div class="right">
            <div class="title">已选系统功能</div>
            <div class="tab">
              <div class="head">
                <div class="h">序号</div>
                <div class="t">功能名称</div>
              </div>
              <div class="list" v-for="(l, i) in functionList" :key="i">
                <div class="nmb">{{ i + 1 }}</div>
                <div class="name">{{ l.menu_title }}</div>
                <div class="del" @click="delFunction(i, l.key, l.fkey)">
                  <a-icon
                    type="close"
                    class="del-icon"
                    :style="{ fontSize: '16px', color: '#e4393c' }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="visible">注：最多可设置16个常用功能</div>
        
      </a-modal>
  </div>
</template>

<script>
import api from "../assets/js/request/api";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      iconList: [
        'OA_SIGNET', 'OA_SCHEDULE', 'OA_REQUEST', 'OA_OFFICE_SUPPLIES_USED', 'OA_LEAVE', 
        'OA_FILES_USED', 'OA_EVECTION', 'OA_CONFERENCE', 'OA_CARS_USED', 'OA_BOOKS',
        'OA_ATTENDANCE', 'CRM_MATERIAL'
      ],
      iconColor: "#e4393c",
      visible: false, //添加弹框的显隐
      // expandedKeys: [], //展开指定的添加树节点
      autoExpandParent: true, // 是否自动展开父节点
      checkedKeys: [], //选中复选框的树节点（注意：父子节点有关联，如果传入父节点 key，则子节点自动选中；相应当子节点 key 都传入，父节点也自动选中。
      treeData: [],
      pupList: [], //全部的常用功能
      functionList: [], //添加右边选中的常用功能
      list: [], //显示的常用功能
      modalText: {children:'children', title:'menu_title', key:'key', value: 'value' }, //树形图文本
      ifDisabled: false,
    };
  },
  created() {
    this.get_list();
    this.getUrl();
  },
  mounted() {
    
  },
  methods: {

    // 获取菜单
    async get_list() {
      let res = await api.getMenus();
      if(res.code == 200) {
        this.pupList = [];
        this.checkedKeys = [];
        this.functionList = [];
        this.list = [];
        this.treeData = [];
        let obj = res.data;
        // console.log(this.treeData, '获取菜单111')
        for(let item in obj) {
          // 菜单第一层
          if(obj[item].children) {
            this.treeData.push(obj[item]);
            let obj2 = obj[item].children
            // 菜单第二层
            obj2.forEach((i)=> {
              if(i.children) {
                // 菜单第三层
                i.children.forEach((sun)=> {
                  let sunObj = sun;
                  sunObj.fkey = i.key;
                  this.pupList.push(sunObj);
                  if(sun.checked) {
                    this.checkedKeys.push(sun.key)
                    this.functionList.push(sun);
                    this.list.push(sun);
                  }
                })
              } else {
                let son = i
                son.fkey = obj[item].key; //给第二层菜单添加上第一层的id
                this.pupList.push(son);
                if (i.checked) {
                  this.checkedKeys.push(i.key)
                  this.functionList.push(i);
                  this.list.push(i);
                }
              }
              
            })
          } else {
            // this.pupList.push(obj[item]);
          }
        }
      }
    },

    getUrl() {
      var url = window.location.href ;             //获取当前url
      // console.log(url, "获取到的链接")
      // var dz_url = url.split('#')[0];                //获取#/之前的字符串 
      // var cs = dz_url.split('?')[1];                //获取?之后的参数字符串
      // var cs_arr = cs.split('&');                    //参数字符串分割为数组
      // var cs={};
      // for(var i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象
      //   cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
      // }
      // console.log(url, cs, '获取到参数')
    },

    // 添加常用菜单
    add_toolbar() {
      this.visible = true;
    },
    // 保存
    async handleOk() {
      let reqArr = []
      this.functionList.forEach((item, i)=>{
        let obj = {
          saas_model_id: item.saas_model_id,
          saas_model_code: item.saas_model_code,
          model_id: item.model_id,
          model_code: item.model_code,
          function_icon: item.model_code,
          function_name: item.model_name,
          function_code: item.id,
          functionl_url: item.menu_url,
          position_sort: i
        }
        reqArr.push(obj);
      })
      console.log({data: reqArr}, '保存')
      let res = await api.updateOftenBtn({data: reqArr});
      if(res) {
        console.log(res, '保存了')
        this.get_list()
      }
      // this.list = JSON.parse(JSON.stringify(this.functionList));
      this.visible = false;
    },
    //删除
    delFunction(i, key, fkey) {
      this.functionList.splice(i, 1);
      let index = this.checkedKeys.indexOf(key);
      this.checkedKeys.splice(index, 1);
      let index2 = this.checkedKeys.indexOf(fkey);
      index2 != -1 ? this.checkedKeys.splice(index2, 1) : "";
    },

    // 菜单跳转
    goPage(l) {
      console.log(window, window.parent, window.parent.onskip, '跳转111')
      window.parent.onskip(l.menu_title, l.menu_url);
    },

    // 选择超过16个菜单，提示弹框
    warning() {
      this.$warning({
        title: '提示',
        content: '选中的菜单已超16个',
      });
    },

    // 树选择事件
    selectBtn(e) {
      // 筛选出选中的父级key,用来判断是否超出16个
      let selectKey = []
      this.pupList.forEach((item) => {
        if(e.indexOf(item.key) != -1) {
          selectKey.push(item.key);
        }
      })
      if(selectKey.length > 16) {
        this.warning()
        return;
      }
      
      let arr = [];
      this.checkedKeys = e;
      this.pupList.forEach((item, index) => {
        if (this.checkedKeys.indexOf(item.key) != -1) {
          arr.push(item);
        } else {
          
        }
      });
      this.functionList = arr;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hello {
  .f {
    position: fixed;
  }
  .toolbar {
    width: 100%;
    margin-bottom: 32rpx;
    box-shadow: 0px 0px 5px 1px rgba($color: #000000, $alpha: 0.3);
    .bor1 {
      text-align: left;
      padding: 12px;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    }
    .list {
      padding: 12px 0;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      .item {
        width: 100px;
        margin: 0 16px 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .iconImg {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #e4393c;
          border-radius: 20px;
          margin-bottom: 10px;
        }
        img {
          width: 24px;
          height: 24px;
        }
        .name {
          line-height: 14px;
        }
        .add-icon {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.popup-up {
  .popup-content {
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      .title {
        margin-bottom: 10px;
      }
    }
    .tree,
    .tab {
      padding: 16px;
      min-width: 300px;

      height: 400px;
      border: 1px solid #ddd;
      overflow-y: auto;
    }

    .tab {
      .head {
        display: flex;
        border: 1px solid #ddd;
        background: #eee;
        .h {
          width: 50px;
          border-right: 1px solid #ddd;
          text-align: center;
        }
        .t {
          flex: 1;
          text-align: center;
        }
      }
      .list {
        display: flex;
        justify-content: space-between;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        line-height: 30px;
        .nmb {
          border-right: 1px solid #ddd;
          width: 50px;
          text-align: center;
        }
        // .name {

        // }
        .del {
          padding-right: 10px;
        }
      }
    }
  }
  .visible {
    color: red;
    margin-top: 10px;
  }
}
</style>
