export default {
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        // 处理时间格式 alex
        unixDatetime(time, type) {
            var date = new Date(time);
            var month = date.getMonth() + 1;
            var day = date.getDate();
            var hour = date.getHours();
            var min = date.getMinutes();
            var sec = date.getSeconds();

            var year = date.getFullYear()
            var year = year < 2000 ? year + 1900 : year
            var yy = year.toString().substr(2, 2);

            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            hour = hour < 10 ? '0' + hour : hour;
            min = min < 10 ? '0' + min : min;
            sec = sec < 10 ? '0' + sec : sec;

            // var dataStr = yy + '-' + month + '-' + day + ' ' + hour + ':' + min; //可在这里调整需要的格式
            if (type == 1) {
                var dataStr = month + '.' + day; //可在这里调整需要的格式
            } else if (type == 2) {
                var dataStr = month + '月' + day + '日';
            } else if (type == 3) {
                var dataStr = year + '-' + month + '-' + day;
            }
            return dataStr;
        },

        // 菜单跳转
        goPage(title, url) {
            console.log(window, window.parent, window.parent.onskip, '跳转111')
            window.parent.onskip(title, url);
        },
    }
}