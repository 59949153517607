import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // token: "58484171-fc35-1561-a209-22ada725ab1d"
    // token: "90c23412-e8c3-8027-dba0-ec8598762ebc"
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
