<template>
    <div class="InformList">
        <div class="item">
            <!-- 待办 -->
            <Inform :msg="'待办'" :types="2"/>
        </div>
        <div class="item">
            <!-- 预警 -->
            <Inform :msg="'预警'" :types="3"/>
        </div>
        <div class="item">
            <!-- 消息 -->
            <Inform :msg="'消息'" :types="1"/>
        </div>
    </div>
</template>

<script>
import Inform from '@/components/Inform.vue';
export default {
    name: 'InformList',
    components: {
        Inform
    }
}
</script>

<style lang="scss">
.InformList {
    .item {
        margin-bottom: 16px;
    }
}
</style>