import { get, post } from './http'

// 获取日程表数据
export function apiGetData(data) {
    return post('/oa/ScheduleApi/getScheduleData', data)
}

// 根据月份获取日程表数据
export function apiDayData(data) {
    return get('/oa/ScheduleApi/getMonthSchedulePc', data)
}

// 获取待办信息数据
export function apiBacklog(data) {
    return post('/oa/WaitListApi/getWaitListList', data)
}

// 获取消息中心数据
export function apiNews(data) {
    return post('/oa/MessageApi/getMessageList', data)
}

// 获取菜单
export function getMenus(data) {
    return post('/oacrm/UserApi/getMenus', data)
}

// 更新常用菜单
export function updateOftenBtn(data) {
    return post('/oacrm/UserApi/updateOftenMenu', data)
}

// 获取常用菜单
export function oftenBtn(data) {
    return post('/oacrm/UserApi/oftenBtn', data)
}

// 获取待办、预警、消息
export function getAllData(data) {
    return post('/oa/CommonApi/getAllData', data)
}

// 改变待办、预警、消息的已读状态
export function editStatus(data) {
    return post('/oa/CommonApi/editStatus', data)
}

// 获取路径
export function getUrl(data) {
    return post('/oa/CommonApi/getUrl', data)
}

export default {
	apiGetData,
    apiDayData,
    apiBacklog,
    apiNews,
    getMenus,
    updateOftenBtn,
    oftenBtn,
    getAllData,
    editStatus,
    getUrl
}
