<template>
  <div class="inform">
    <div class="content">
      <div class="top">
        <div class="h1 c1 bor1">{{ msg }}</div>
        <div class="btn-list">
          <!-- <div class="btn">更多</div> -->
          <div class="btn refresh" @click="refresh">刷新</div>
          <!-- <a-icon
            type="close"
            class="del-icon"
            :style="{ fontSize: '16px', color: '#4d4d4d' }"
          /> -->
        </div>
      </div>
      <div class="list" ref="scroll1">
        <div v-if="!ifLoading">
          <div class="item" v-for="(l, i) in list" :key="i" @click="goDet(l)">
            <div class="item-left">
              <img v-if="types == 2" src="../assets/img/type1.png" alt="" />
              <img v-if="types == 3" src="../assets/img/type2.png" alt="" />
              <img v-if="types == 1" src="../assets/img/type3.png" alt="" />
              <div class="title d1">{{ l.title }}</div>
            </div>
            <div v-if="types ==2" class="state" :class="l.is_readed != 0 ? 'read' : ''">待处理</div>
            <div v-if="types ==3" class="state" :class="l.is_readed != 0 ? 'read' : ''">待处理</div>
            <div v-if="types ==1" class="state" :class="l.cur_status != 1 ? 'read' : ''">去查看</div>
          </div>
          <div class="empty" v-if="list.length == 0">
            <a-empty description="暂无数据" />
          </div>
        </div>
        <div v-else class="loading">
          <a-spin :spinning="ifLoading"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api, { editStatus } from "../assets/js/request/api";
export default {
  name: "inform",
  props: {
    msg: {
      type: String,
      default: "",
    },
    types: {
      type: Number,
      default: 1,
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    this.$nextTick(() => {
      //监听这个dom的scroll事件
      this.scroll1.addEventListener('scroll', this.handleScroll);
    })
  },
  computed: {
    scroll1() {
      return this.$refs.scroll1;
    }
  },
  data() {
    return {
      list: [], //数据列表
      ifLoading: true, //是否加载中
      status: "nomore", // loadmore:加载更多 loading 加载中 nomore 没有更多
			page: 1,
    };
  },
  methods: {
    // 获取待办、预警、消息数据列表
    async getList() {
      this.status = 'loading';
      let res = await api.getAllData({
        type: this.types,
        limit: 10,
        page: this.page
      });
      if (res.code) {
        if(this.page == 1) {
          this.list = res.data.data;
        } else {
          this.list = [...this.list, ...res.data.data]
        }
        if(res.data.current_page < res.data.last_page) {
          this.status = 'loadmore';
        } else {
          this.status = 'nomore';
        }
      }
      this.ifLoading = false;
      // console.log(res, "待办");
    },

    // 跳转待办、预警、消息详情
    async goDet(l) {
      await this.getUrl(l);
      await this.editStatus(l)
    },

    // 改变待办、预警、消息已读状态
    async editStatus(l) {
      let res = await api.editStatus({
        type: this.types,
        id: l.id
      })
      if(res) {
        console.log(l, res, '已读状态')
        this.refresh();
      }
    },

    // 获取后台路由数据
    async getUrl(l) {
      console.log(Number(l.relation_type), )
      let res = await api.getUrl({
        relation_type: Number(l.relation_type),
        id: Number(l.relation_id)
      }) 
      if(res.st == 1) {
        console.log(l, res, '路由')
        this.goPage(res.name, res.url);
      }
    },

    // 刷新
    refresh() {
      this.status = 'loading'
      this.page = 1;
      this.getList();
    },

    // 判断是否到达底部
    handleScroll() {
      // console.log(this.scroll1.scrollTop, '滚动了')
      // 获取dom滚动距离
      const scrollTop = this.scroll1.scrollTop;
      // 获取可视区高度
      const offsetHeight = this.scroll1.offsetHeight;
      // 获取滚动条总高度
      const scrollHeight = this.scroll1.scrollHeight;
      //距离顶部的距离加上可视区区域的高度等于或者大于滚动条的总高度就是到达底部
      if(scrollTop + offsetHeight >= scrollHeight) {
        if (this.status == "nomore") return;
        this.page++;
        this.status = "loading";
        this.getList();
        console.log('到达底部')
      }
    },
  },
};
</script>

<style lang="scss">
.inform {
  padding-left: 16px;
  .content {
    box-shadow: 0px 0px 5px 1px rgba($color: #000000, $alpha: 0.3);
    min-width: 300px;
    .top {
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 24px 24px 14px;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      .btn-list {
        display: flex;
        align-content: center;
        .btn {
          margin: 0 0 0 14px;
          cursor: pointer;
        }
        .refresh {
          color: #e4393c;
          border: 1px solid #e4393c;
          padding: 0 7px;
          line-height: 22px;
          border-radius: 11px;
        }
        .del-icon {
          line-height: 24px;
        }
      }
    }
    .list {
      width: 100%;
      padding: 0 18px 16px;
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      .item {
        display: flex;
        justify-content: space-between;
        align-content: center;
        background: #fbfbfb;
        padding: 0 8px;
        margin-top: 14px;
        height: 34px;
        cursor: pointer;
        .item-left {
          min-width: 80%;
          display: flex;
          align-content: center;
          img {
            width: 20px;
            height: 20px;
            margin: auto 0;
          }
          div {
            margin-left: 6px;
            line-height: 34px;
          }
          .title {
            text-align: left;
            margin: 0 10px;
            min-width: 100px;
          }
        }
        .state {
          min-width: 50px;
          line-height: 34px;
          color: #e4393c;
        }
        .read {
          color: #999;
        }
      }
      .loading {
        padding: 16px;
      }
    }
  }
}

.empty {
  margin-top: 16px;
}
</style>