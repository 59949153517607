<template>
  <div class="home">
    <a-row type="flex" justify="center" align="top">
      <a-col :span="16">
        <!-- 左边功能 -->
        <HelloWorld/>
        <!-- 日程表 -->
        <schedule/>
      </a-col>
      <a-col :span="8">
        <!-- 右边通知 -->
        <InformList/>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue';
import InformList from '@/components/InformList.vue';
import schedule from '@/components/schedule.vue';

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    InformList,
    schedule
  }
}
</script>

<style lang="scss">
  .home {
    padding: 16px;
  }
</style>
