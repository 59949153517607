<template>
  <div class="schedule">
    <div class="h1 c1 bor1">日程表</div>
    <div v-if="calendar">
      <a-config-provider :locale="locale">
        <a-calendar
          @select="selectDate"
          @panelChange="panelChange"
          @change="change"
        >
          <ul
            slot="dateCellRender"
            slot-scope="value"
            class="events"
          >
            <li v-for="(item, index) in getListData(value)" :key="index">
              <a-badge :status="item.type" :text="item.content" />
            </li>
            <!-- <div v-if="show && " class="dle">
              这是悬停事件
          </div> -->
          </ul>
          <template slot="monthCellRender" slot-scope="value">
            <div v-if="getMonthData(value)" class="notes-month">
              <section>{{ getMonthData(value) }}</section>
              <span>Backlog number</span>
            </div>
          </template>
        </a-calendar>
      </a-config-provider>
    </div>

    <a-modal
        wrapClassName="popup-up"
        v-model="visible"
        title="日程待办"
        @ok="handleOk"
        okText="关闭"
        :cancel-button-props="{style: { display: 'none'}}"
        width="500px"
        destroyOnClose
        v-drag-modal
        :dialogStyle="{top: '400px'}"
      >
        <a-empty description="暂无日程内容" v-if="pupopVal.length == 0"/>
        <div v-else>
          <div class="richen" v-for="(l, i) in pupopVal" :key="i">
            <div class="bor1 c1 h1">标题：{{l.schedule_title}}</div>
            <a-badge status="success" :text="l.memo" />
          </div>
        </div>
      </a-modal>
  </div>
</template>

<script>
// import zh_CN from '/ant-design-vue/lib/locale-provider/zh_CN';
import zhCN from "../../src/assets/js/lib/locale-provider/zh_CN";
import scheduleApi from "../assets/js/request/api";
export default {
  name: "schedule",
  props: {
    msg: String,
  },
  data() {
    return {
      locale: zhCN,
      show: false,
      date: new Date(), //当天时间
      scheduleList: [], //月的日程
      calendar: false, //日历显示
      visible: false, //弹框的显隐
      pupopVal: [], //弹框内容
    };
  },
  async created() {
    this.getData();
    this.date = this.date.getFullYear() + '-' + (this.date.getMonth() + 1);
    // console.log(this.date, '11111');
    await this.getDayData({
      schedule_month: this.date,
    });
  },
  methods: {
    // 获取所有日程数据
    async getData() {
      let res = await scheduleApi.apiGetData();
      // console.log(res, "数据");
    },

    //根据月获取日的日程数据
    async getDayData(d) {
      let res = await scheduleApi.apiDayData(d);
      if (res.code == 200) {
        this.scheduleList = res.data || [];
        this.calendar = true;
      }
      // console.log(res, "根据月获取日的日程数据");
    },

    onEnterTd(e) {
      console.log(this.unixDatetime(e._d, 3), "点击日期");
    },

    onLeaveTd() {},

    getListData(value) {
      // console.log(value.format("YYYY-MM-DD"), "当前日期");
      let dateTime = value.format("YYYY-MM-DD");
      let listData = [];
      if (this.scheduleList.length != 0) {
        this.scheduleList.forEach((item) => {
          if (item.schedule_date == dateTime) {
            let obj = {
              type: "success",
              content: item.schedule_title + "," + item.schedule,
            };
            listData.push(obj);
          }
        });
      }
      return listData || [];
    },

    getMonthData(value) {
      if (value.month() === 8) {
        return 1394;
      }
    },

    // 点击日期
    selectDate(e) {
      let time = this.unixDatetime(e._d, 3);
      let arr = [];
      this.scheduleList.forEach((item)=>{
        if(time == item.schedule_date) {
          // console.log(item, '今天的日程')
          arr.push(item);
        }
      })
      this.pupopVal = arr;
      this.visible = true;
    },

    // 关闭弹框
    handleOk() {
      this.visible = false;
    },

    //日期面板变化回调
    panelChange(e) {
      console.log(e, "日期面板变化回调");
      let dateTime = e.format("YYYY-MM-DD");
      this.getDayData({ schedule_month: dateTime });
    },

    //日期变化时的回调, 面板变化有可能导致日期变化
    change(e) {
      console.log(e, "日期变化时的回调, 面板变化有可能导致日期变化");
    },
  },
};
</script>

<style lang="scss">
.schedule {
  width: 100%;
  margin-top: 16px;
  box-shadow: 0px 0px 5px 1px rgba($color: #000000, $alpha: 0.3);
  .bor1 {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  }
  .events {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .dle {
    position: absolute;
    bottom: -10px;
  }
  .events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
  }
  .notes-month {
    text-align: center;
    font-size: 28px;
  }
  .notes-month section {
    font-size: 28px;
  }
  .ant-fullcalendar-fullscreen .ant-fullcalendar-content {
    width: auto;
    padding: 5px 10px;
    height: 60%;
    overflow: hidden;
  }
  .ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
    padding-right: 0;
    padding-top: 5px;
    background: #f0f0f0;
    border: 1px solid #ddd;
  }
  .ant-fullcalendar table,
  .ant-fullcalendar th,
  .ant-fullcalendar td {
    border: 1px solid #ddd;
    padding: 0px;
  }
  .ant-fullcalendar-fullscreen .ant-fullcalendar-month,
  .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
    border-top: 0px;
    padding: 0px;
    margin: 0px;
  }
  // 日历头部
  .ant-fullcalendar-header {
    padding: 11px 16px;
    text-align: left;
  }

  //星期几
  .ant-fullcalendar-column-header-inner {
    text-align: center;
  }

  //日期
  .ant-fullcalendar-fullscreen .ant-fullcalendar-value {
    text-align: center;
    border-bottom: 1px solid #ddd;
  }

  // 日期单元格的高度
  .ant-fullcalendar-fullscreen .ant-fullcalendar-month, .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
    height: 70px;
  }
}

// .ant-modal-wrap {
//   .ant-modal {
//     top: 400px;
//   }
// }
</style>